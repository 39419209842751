<template>
	<div id="menu_change_trains"></div>
</template>
<script setup>
	import {
		getCurrentInstance
	} from 'vue'

	// 访问 globalProperties
	const ctx = getCurrentInstance().appContext.config.globalProperties

	if (ctx.$system_.state.refresh_to_path) {
		ctx.$router.push({
			name: ctx.$system_.state.refresh_to_path
		})
	} else {
		ctx.$router.push({
			name: ctx.$system_.state.frame_path
		})
	}
</script>
